import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { getServerSession } from 'next-auth/next';
import { signIn } from 'next-auth/react';
import { i18n, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as Yup from 'yup';
import { OAuthButtonGroup } from '../../components/auth/OAuthButtonGroup';
import { DarkLogo } from '../../components/layouts/navbar/DarkLogo';
import { LightLogo } from '../../components/layouts/navbar/LightLogo';
import FormikControl from '../../components/utils/FormikControl';
import { authOptions } from '../api/auth/[...nextauth]';

const LoginPage = () => {
  const router = useRouter();
  const logo = useColorModeValue(<DarkLogo />, <LightLogo />);
  //translation i18next
  const currentLocale = i18n.language;

  const { t } = useTranslation(['auth', 'common', 'validation']);
  const loginText = t('auth:login-to-account');
  const accountText = t('auth:account-dont-have');
  const remmemberText = t('auth:remember');
  const passwordForgot = t('auth:password-forgot');
  const passwordInvalid = t('validation:password-or-email-invalid');
  const signInWithText = t('auth:sign-with');
  const signUpText = t('auth:sign-up');
  const passwordText = t('common:password');
  const emailText = t('common:email');
  const requiredText = t('validation:required-field');
  const emailInvalidText = t('validation:email.invalid');
  const signInText = t('common:sign-in');
  const [errorCredentialsSignin, setErrorCredentialsSignin] = useState(false);

  const handleSignUp = () => {
    router.push('/auth/register');
    return;
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(emailInvalidText).required(requiredText),
    password: Yup.string().required(requiredText),
  });

  const onSubmit = async (values, props) => {
    const res = await signIn('credentials', {
      email: values.email,
      password: values.password,
      callbackUrl: '/migrations',
      redirect: false,
    });

    if (res?.ok) {
      router.push('/migrations');
      return;
    } else {
      setErrorCredentialsSignin(true);
    }
    props.setSubmitting(false);
  };

  return (
    <>
      <Container
        maxW="xl"
        py={{
          base: '12',
          md: '24',
        }}
        px={{
          base: '0',
          sm: '8',
        }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            {logo}
            <Stack
              spacing={{
                base: '2',
                md: '3',
              }}
              textAlign="center"
            >
              <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                {loginText}
              </Heading>
              <HStack spacing="1" justify="center">
                <Text color="muted">{accountText}</Text>
                <Button
                  variant="link"
                  colorScheme="blue"
                  onClick={handleSignUp}
                >
                  {signUpText}
                </Button>
              </HStack>
            </Stack>
          </Stack>
          <Box
            py={{
              base: '0',
              sm: '8',
            }}
            px={{
              base: '4',
              sm: '10',
            }}
            bg={useBreakpointValue({
              base: 'transparent',
              sm: 'bg-surface',
            })}
            boxShadow={{
              base: 'none',
              sm: useColorModeValue('md', 'md-dark'),
            }}
            borderRadius={{
              base: 'none',
              sm: 'xl',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <Stack spacing="6">
                      <Stack spacing="5">
                        <FormikControl
                          control="input"
                          type="email"
                          label={emailText}
                          name="email"
                        />

                        <FormikControl
                          control="password"
                          label={passwordText}
                          name="password"
                        />
                      </Stack>
                      <HStack justify="space-between">
                        <Checkbox defaultChecked>{remmemberText}</Checkbox>
                        <Link
                          href="/auth/forgot-password/"
                          locale={currentLocale}
                        >
                          <Button variant="link" colorScheme="blue" size="sm">
                            {passwordForgot}
                          </Button>
                        </Link>
                      </HStack>
                      <Stack spacing="6">
                        <Button
                          mt={4}
                          colorScheme="blue"
                          isLoading={formik.isSubmitting}
                          disabled={!formik.isValid}
                          type="submit"
                        >
                          {signInText}
                        </Button>
                        {errorCredentialsSignin && (
                          <Text
                            fontSize="sm"
                            color="red.300"
                            textAlign="center"
                          >
                            {passwordInvalid}
                          </Text>
                        )}
                        <HStack>
                          <Divider />
                          <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                            {signInWithText}
                          </Text>
                          <Divider />
                        </HStack>
                        <OAuthButtonGroup />
                      </Stack>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export const getServerSideProps = async (context) => {
  const { req, res } = context;
  const session = await getServerSession(req, res, authOptions);

  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: '/migrations',
      },
    };
  } else {
    return {
      props: {
        data: null,
        ...(await serverSideTranslations(
          context.locale,
          ['auth', 'common', 'validation'],
          null,
          context.locales
        )),
      },
    };
  }
};

export default LoginPage;
